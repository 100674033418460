import React, { Component, Fragment } from 'react';

import styled from 'styled-components';
import { Menu } from 'styled-icons/material/Menu';
import { Close } from 'styled-icons/material/Close';
import OffCanvas from 'react-aria-offcanvas';
import { NavLink } from 'react-router-dom';

import media from '../util/media';

const NavBarStyles = styled.div`
  nav {
    display: flex;
    flex-direction: column;
    ${media.medium`
      flex-direction: row;
      width: 100vw;
    `}

    justify-content: center;
    a {
      padding: 0 0.4em;
      color: ${props => props.theme.acColor};
      text-decoration: none;
      &:focus, &.active {
        text-decoration: underline;
        font-size: 110%;
      }
      &:hover {
        font-size: 110%;
      }
      transition: font-size 0.4s;
      transition-timing-function: ease-in;
    }
  }
  font-size: ${props => props.theme.fontSize(2)};
  line-height: 170%;
  font-family: ${props => props.theme.headFontFamily}, sans-serif;
  font-weight: ${props => props.theme.headFontWeight};
  text-transform: uppercase;
  font-variant-caps: titling-caps;
  color: ${props => props.theme.fgColor};
  background-color: ${props => props.theme.bgColor};
  border-bottom: 4px solid ${props => props.theme.acColor};
`;

const NavBar = () => (
  <NavBarStyles>
    <nav id="site-menu">
      <NavLink exact to="/">Home</NavLink>
      <NavLink exact to="/band">Band</NavLink>
      <NavLink exact to="/media">Media</NavLink>
      <NavLink exact to="/agenda">Agenda</NavLink>
    </nav>
  </NavBarStyles>
);

const CollapsibleNav = styled.div`
  display: flex;
  ${media.medium`display: none`};
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  #menu-button, #menu-close {
    padding: 0;
    position: fixed;
    right: 2vw;
    top: 2vw;
    color: ${props => props.theme.fgColor};
    background-color: transparent;
    border-style: none;
    border-width: 0;
  }
`;

const TopBarNav = styled.div`
  display: none;
  ${media.medium`display: flex`};
`;

export default class SiteNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <Fragment>
        <CollapsibleNav>
          <OffCanvas
            isOpen={this.state.isOpen}
            onClose={this.close}
            labelledby="menu-button"
            position="top"
            returnFocusAfterClose={false}
            width="100vw"
          >
            <button
              id="menu-close"
              onClick={this.close}>
              <Close size="40" title="close menu"/>
            </button>
            <NavBar/>
          </OffCanvas>
          <button
            id="menu-button"
            aria-label="Menu"
            aria-controls="site-menu"
            aria-expanded={this.state.isOpen}
            onClick={this.open}
          >
            <Menu size="40" title="close menu"/>
          </button>
        </CollapsibleNav>
        <TopBarNav>
          <NavBar/>
        </TopBarNav>
      </Fragment>
    );
  }
}
