import React, { Fragment } from 'react';

import styled from 'styled-components';
import { Head } from 'react-static';

import GlobalStyle from './GlobalStyle';
import Footer from './Footer';
import VectorLogoBlock from './VectorLogoBlock';
import SiteNav from './SiteNav';
import media from '../util/media';

const OuterStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  h1 {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
`;

const sizedImage = (size, props) => {
  const img = (props.bgImage || props.theme.bgImage || 'img/WFG21427_sm.jpg')
    .replace(/_sm\./, `_${size}.`);
  return `background: url(${img}) center / cover no-repeat;`
};

const MainContentStyles = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5vh 0;
  justify-content: space-around;
  min-width: 100vw;
  {${props => sizedImage('sm', props)}}
  ${media.medium`${props => sizedImage('md', props)}`}
  ${media.large`${props => sizedImage('lg', props)}`}
  font-size: ${props => props.theme.fontSize(0)};
  color: ${props => props.theme.fgColor};

  h2, h3, h4, h5 {
    font-family: ${props => props.theme.headFontFamily}, sans-serif;
    font-weight: ${props => props.theme.headFontWeight};
  }
  h2 {
    font-size: ${props => props.theme.fontSize(3)};
    font-style: italic;
  }
  h3 {
    font-size: ${props => props.theme.fontSize(2)};
    font-style: normal;
  }
  h4 {
    font-size: ${props => props.theme.fontSize(1)};
    font-style: italic;
  }
  h5 {
    font-size: ${props => props.theme.fontSize(0)};
    font-style: normal;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.fgColor};
  }
  pre, code {
    user-select: text;
  }
  em ,i {
    font-style: italic;
    font-size: 94%; // JAF Bernina italic looks slightly too big in running text
  }
  pre {
    font-size: ${props => props.theme.fontSize(0)};
    border-radius: 5px;
  }
`;

function addTitle(props) {
  if (!props.skipPageHead)
    return <h2>{props.pageTitle}</h2>
}

function addLogo(props) {
  if (!props.skipPageHead)
    return <VectorLogoBlock/>
}

class BoraPage extends React.Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    this.state = {  };
  }

  render() {
    return (<OuterStyles>
        <GlobalStyle/>
        <Head>
          <title>{this.props.pageName} | bora saxofoonkwartet</title>
        </Head>
        <h1>Bora blaast, Bora raast, fluisterzacht en intiem, oorstrelend spektakel</h1>
          <Fragment>
            <SiteNav/>
            <MainContentStyles bgImage={this.props.bgImage}>
              {addLogo(this.props)}
              {addTitle(this.props)}
              {this.props.children}
            </MainContentStyles>
            <Footer/>
          </Fragment>
      </OuterStyles>
    );
  };
}

export default BoraPage;
