import { css } from 'styled-components';

// Usage:
// const Container = styled.div`
//   padding: 0;
//   ${media.large`padding: 0 20px;`}
//   ${media.medium`padding: 0 10px;`}
//   ${media.small`padding: 0 5px;`}
// `

// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#media-templates

const sizes = {
  large: 1060,
  medium: 764,
  small: 377,
};

// iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media screen and (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export default media;
