import React from 'react';
import styled from 'styled-components';
import VectorLogo from '-!svg-react-loader?name=VectorLogo&props[style:junk]!../../public/bora-logo-transparant.svg';

const GradientStyles = () => (
  <svg aria-hidden="true" className="gradient" focusable="false">
  <linearGradient id="bora-logo-gradient" x2="1" y2="1">
    <stop offset="0%" stopColor="#f8fdff" />
    <stop offset="100%" stopColor="#f0f8fc" />
  </linearGradient>
</svg>);

const VectorLogoBlockStyles = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 100px;
  .gradient {
    width: 0;
    height: 0;
    position: absolute;
  }
  .logo {
    width: 15rem;
    margin: 10px;
    & g path {
      fill: url(#bora-logo-gradient) ${props => props.theme.fgColor} !important; // TODO: strip cruft from SVG using svgo
    }
  }
`;

const VectorLogoBlock = () => (
  <VectorLogoBlockStyles className="logoText">
    <GradientStyles/>
    <VectorLogo id="bora-logo" className="logo"/>
  </VectorLogoBlockStyles>
);

export default VectorLogoBlock;
