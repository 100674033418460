import { createGlobalStyle } from 'styled-components';

// This needs to set basically anything that affects global page layout elements
// because the non-global styles will be at least one level below <body>
const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }
  body {
    font-family: ${props => props.theme.textFontFamily}, sans-serif;
    font-weight: ${props => props.theme.textFontWeight};
    font-size: 1rem;
    color: ${props => props.theme.bgColor}
    font-kerning: normal;
    font-variant-ligatures: common-ligatures contextual;
    font-feature-settings: "kern", "liga", "clig", "calt", "onum", "pnum";
    overflow-wrap: normal;
    margin: 0;
    padding: 0;
    line-height: 1.4;
    
    background: linear-gradient(135deg, ${props => props.theme.fgColor}, ${props => props.theme.bgColor}) fixed;
  }
  #root {
    min-height: 100vh;
  }
`;

export default GlobalStyle;
