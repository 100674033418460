import React from 'react';
import styled from 'styled-components';

import LogoText from './LogoText';

import media from '../util/media';
import MarimbaLogoText from './MarimbaLogoText';

const FooterStyles = styled.div`
  min-height: 8em;
  min-width: 100vw;
  font-size: ${props => props.theme.fontSize(-1)};
  line-height: 170%;
  color: ${props => props.theme.fgColor};
  background-color: ${props => props.theme.bgColor};
  padding: 1.7em 0;
  border-top: 4px solid ${props => props.theme.acColor};
  .grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${media.medium`
      flex-direction: row;
      flex-wrap: nowrap;
    `};
    justify-content: center;
  }
  .attribution {
    margin: 1.4rem 0 0;
  }
  .footcol {
    flex: 1;
    & > * {
      flex: 1;
    }
    &.fc-2 {
      ${media.medium`order: 3`} // show at end
    }
    .head {
      font-weight: bold;
    }
    &.fc-3 {
      margin-top: 1.4rem;
      ${media.medium`margin-top: 0`}
    }
  }
  p,
  a {
    margin: 0;
    padding: 0;
    color: inherit;
  }
`;

const Footer = () => (
  <FooterStyles>
    <div className="grid">
      <div className="footcol fc-1">
        <p>
          <LogoText/>
        </p>
        <p>
          <a href="tel:+31649677251">+31 6 49677251</a>
        </p>
        <p>
          <a href="mailto:bora@marimba.nl">bora@marimba.nl</a>
        </p>
        <p>
          <a href="https://www.facebook.com/Borasaxofoonkwartet" target="_blank" rel="noopener noreferrer">
            facebook.com/Borasaxofoonkwartet
          </a>
        </p>
      </div>
      <div className="footcol fc-3">
        <p>
          Carla de Roode – alt- en sopraansaxofoon
        </p>
        <p>
          Hannah van Weers – altsaxofoon
        </p>
        <p>
          Erik Schötker – tenorsaxofoon
        </p>
        <p>
          Marja Weijerman – baritonsaxofoon
        </p>
      </div>
    </div>
    <div className="grid attribution">
      <div className="footcol fc-2">
        <p>
          &copy; 2019&ndash;2024 <LogoText/>
        </p>
        <p>ontwerp en realisatie door&nbsp;
          <a href="https://marimba.nl" target="_blank" rel="noopener noreferrer">
            <MarimbaLogoText/>
          </a>
        </p>
      </div>
    </div>
  </FooterStyles>
);

export default Footer;
