import React from 'react';
import styled from 'styled-components';

const MarimbaLogoTextStyles = styled.span`
  font-family: co-headline, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
`;

const MarimbaLogoText = () => (
  <MarimbaLogoTextStyles className="logoText">marimba.nl</MarimbaLogoTextStyles>
);

export default MarimbaLogoText;
